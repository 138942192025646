import React, { useState } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import '../styles/Login.css';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const subdomain = window.location.host.split('.')[0];
    const buttonColor = subdomain === 'vspj' ? "btn btn-vspj" : "btn btn-opf";

    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const formData = {
            emailAddress: email,
            password: password
        };

        const response = await fetch('login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            navigate('/edit-registration', { state: { email } });
        } else {
            window.alert("Špatný e-mail nebo heslo.");
        }
    }

    return (
        <Form onSubmit={handleLogin} className="mt-4 full-height w-50 mx-auto">
            <FormGroup>
                <Label for="email">E-mail</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Zadej e-mail"
                    value={email}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">Heslo</Label>
                <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Zadej heslo"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormGroup>
            <Button type="submit" className={buttonColor}>Log in</Button>
        </Form>
    );
}

export default LoginForm;
