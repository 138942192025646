import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../styles/SessionDates.css'

export class SessionDates extends Component {
    static displayName = SessionDates.name;
    render() {
        const subdomain = window.location.host.split('.')[0];
        const firstSessionDate = subdomain === 'vspj' ? "10.9. - 12.9.2024" : "10.9. - 12.9.2024";
        const freeSpots = subdomain === 'vspj' ? "free-spots-vspj ms-lg-0 ms-xl-3" : "free-spots-opf ms-lg-0 ms-xl-3";

        return (
        <Container className="session-dates pt-4">
            <Row className="justify-content-center align-items-center mt-4 mb-2 text-center">
                <Col md="6" className="mb-4 mb-md-0">
                    <h1>Termín:</h1>
                </Col>
                <Col md="6">
                    <h3>{firstSessionDate} <span className={freeSpots}>Místa stále jsou!</span></h3>
                </Col>
            </Row>
        </Container>
        );
    }
}