import React, { useState } from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row, NavItem,
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";

const RegistrationForm = () => {
    const subdomain = window.location.host.split('.')[0];
    const buttonColor = subdomain === 'vspj' ? "btn btn-vspj mb-3" : "btn btn-opf-registration mb-3";

    const sessionOptions = subdomain === "vspj" ? [
        { value: '', label: 'Vybrat...' },
        { value: 'vspj-2024', label: '10.9 - 12.9.2024' },
    ] : [
        { value: '', label: 'Vybrat...' },
        { value: 'opf-2024', label: '10.9. - 12.9.2024' },
    ];

    const departmentOptions = subdomain === "vspj" ? [
        { value: '', label: 'Vybrat...' },
        { value: 'AppliedInformaticsVspj', label: 'Aplikovaná informatika' },
        { value: 'AppliedTechnologyForIndustryVspj', label: 'Aplikovaná technika pro průmyslovou praxi' },
        { value: 'AppliedMechanicalEngineeringVspj', label: 'Aplikované strojírenství' },
        { value: 'TravelAndTourismVspj', label: 'Cestovní ruch' },
        { value: 'FinanceAndManagmentVspj', label: 'Finance a řízení' },
        { value: 'BirthAssistentVspj', label: 'Porodní asistentka' },
        { value: 'NurseVspj', label: 'Všeobecná sestra' },
        { value: 'HealthAndSocialCareVspj', label: 'Zdravotně sociální péče' },
        { value: 'OtherVspj', label: 'Jiný...' }
    ] : [
        { value: '', label: 'Vybrat...' },
        { value: 'BankingOpf', label: 'Bankovnictví, peněžnictví, účetnictví' },
        { value: 'TravelAndTourismOpf', label: 'Cestovní ruch a turismus' },
        { value: 'DigitalBusinessOpf', label: 'Digitální business' },
        { value: 'EconomicsAndManagementOpf', label: 'Economics and management' },
        { value: 'FinanceAndAccountingOpf', label: 'Finance a účetnictví' },
        { value: 'InnovativeBusinessOpf', label: 'Inovativní podnikání' },
        { value: 'SocialServicesMarketingOpf', label: 'Management v sociálních službách' },
        { value: 'ManagerialItOpf', label: 'Manažerská informatika' },
        { value: 'MarketingOpf', label: 'Marketing' },
        { value: 'InternationalTradeOpf', label: 'Mezinárodní obchod' },
        { value: 'TradeAndMarketingOpf', label: 'Obchod a marketing' },
        { value: 'BusinessOpf', label: 'Podnikání' },
        { value: 'PublicEconomyOpf', label: 'Veřejná ekonomika a správa' },
        { value: 'OtherOpf', label: 'Jiný...' }
    ];

    const [isSubmitting, setIsSubmitting] = useState(false);
    
    function checkEmptyStrings(formData) {
        for (const key in formData) {
            if (key !== "roommates")
                if (formData[key] === "") {
                    return true; // Empty string found
                }
        }
        return false; // No empty strings found
    }



    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        department: "",
        firstYear: "",
        birthDate: "",
        roommates: "",
        gender: "",
        session: "",
        transportMode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        country: "",
    });

    function isValidEmail(email) {
        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: updatedValue,
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail(formData.emailAddress)) {
            window.alert("Zadej platnou emailovou adresu.");
            return;
        }

        if (checkEmptyStrings(formData)) {
            window.alert("Vyplň prosím všechny údaje. Spolubydlící jsou nepovinní.");
            return;
        }

        setIsSubmitting(true);
        
        const response = await fetch('registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        setIsSubmitting(false);
        
        if (response.ok) {
            navigate('/thank-you');
        } else {
            window.alert("Tato adresa už je použitá.");
        }
    };

    return (
        <div>
            <h1>Nová přihláška</h1>
            <Form onSubmit={handleSubmit}>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="firstName">Jméno</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="lastName">Příjmení</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="emailAddress">E-mail</Label>
                    <Input
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="phoneNumber">Telefonní číslo</Label>
                    <Input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                    <small className="text-muted">
                        Zadej telefonní číslo včetně předvolby, např. +420123456789.
                    </small>
                </FormGroup>
                <FormGroup>
                    <Label for="department">Obor</Label>
                    <Input
                        type="select"
                        name="department"
                        id="department"
                        value={formData.department}
                        onChange={handleChange}
                    >
                        {departmentOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <p>Nastupuji do prváku:</p>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="firstYear"
                                value="True"
                                onChange={handleChange}
                                checked={formData.firstYear === "True"}
                            />{" "}
                            Ano
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="firstYear"
                                value="False"
                                onChange={handleChange}
                                checked={formData.firstYear === "False"}
                            />{" "}
                            Ne
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="birthDate">Datum narození</Label>
                    <Input
                        type="date"
                        name="birthDate"
                        id="birthDate"
                        value={formData.birthDate}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="roommates">Spolubydlící (Chci bydlet s kamarádem/kou)</Label>
                    <Input
                        type="text"
                        name="roommates"
                        id="roommates"
                        value={formData.roommates}
                        onChange={handleChange}
                    />
                    <small className="text-muted">
                        Zadej emaily svých spolubydlících a odděl je čárkou (např. beruska@seznam.cz, harrypotter@gmail.com). Pokud jedeš sám/sama, nech toto pole prázdné.
                    </small>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <p>Pohlaví</p>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="gender"
                                value="male"
                                onChange={handleChange}
                            />{" "}
                            Muž
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="gender"
                                value="female"
                                onChange={handleChange}
                            />{" "}
                            Žena
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="session">Turnus</Label>
                    <Input
                        type="select"
                        name="session"
                        id="session"
                        value={formData.session}
                        onChange={handleChange}
                    >
                        {sessionOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="transportMode">Způsob dopravy</Label>
                    <Input
                        type="select"
                        name="transportMode"
                        id="transportMode"
                        value={formData.transportMode}
                        onChange={handleChange}
                    >
                        <option value="">Vybrat...</option>
                        <option value="car">Naším autobusem od školy</option>
                        <option value="bus">Vlastní</option>
                    </Input>
                </FormGroup>
                <legend>Fakturační údaje</legend>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="addressLine1">Ulice:</Label>
                            <Input
                                type="text"
                                name="addressLine1"
                                id="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="addressLine2">Č. p.:</Label>
                            <Input
                                type="text"
                                name="addressLine2"
                                id="addressLine2"
                                value={formData.addressLine2}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="city">Město:</Label>
                            <Input
                                type="text"
                                name="city"
                                id="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="postalCode">PSČ:</Label>
                            <Input
                                type="text"
                                name="postalCode"
                                id="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="country">Země:</Label>
                            <Input
                                type="text"
                                name="country"
                                id="country"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Button className={buttonColor} type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Odesílání..." : "Odeslat"}
                </Button>
            </Form>
            <small className="text-muted">
                Odesláním formuláře nám dáváš svůj souhlas se zpracováním osobních údajů a souhlasíš s obchodními podmínkami společnosti Adapťák s.r.o. S tvými osobními údaji budeme nakládat v souladu se zákonem a pouze pro potřeby společnosti Adapťák s.r.o.
            </small>
        </div>
    );
};

export default RegistrationForm;