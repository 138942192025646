import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/SessionSelector.css';

const SessionSelector = () => {
    return (
        <Container className="component-container">
            <Row className="d-flex justify-content-center align-items-center text-center">
                <Col>
                    <div className="d-flex align-items-center">
                        <div>
                            <img
                                alt="Adapťák logo"
                                src={require('../images/adaptak_logo.png')}
                                className="me-2 logo"
                            />
                        </div>
                        <div className="text-start">
                            <div>Adapťák</div>
                            <div className="text-muted">Pro studenty prvních ročníků</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="pt-5">
                <h3>Vyber si svou školu:</h3>
            </Row>
            <Row className="d-flex justify-content-center align-items-center text-center">
                <Col md="6">
                    <div className="logo-container">
                        <a href="https://slu.adaptak.com">
                            <div className="logo-wrapper">
                                <img
                                    src={require('../images/opf_logo.png')}
                                    alt="Logo 1"
                                    className="logo-img"
                                />
                            </div>
                        </a>
                    </div>
                </Col>
                <Col md="6">
                    <div className="logo-container">
                        <a href="https://vspj.adaptak.com">
                            <div className="logo-wrapper">
                                <img
                                    src={require('../images/vspj_logo.png')}
                                    alt="Logo 2"
                                    className="logo-img"
                                />
                            </div>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SessionSelector;
