import React, { useState } from "react";
import { Collapse, Card, CardBody, CardHeader } from "reactstrap";
import '../styles/AccordionFaq.css'

const AccordionFaq = ({ faqs }) => {
    const subdomain = window.location.host.split('.')[0];
    const accordionHeader = subdomain === 'vspj' ? "accordion-header-vspj" : "accordion-header-opf";
    const accordionBody = subdomain === 'vspj' ? "accordion-body-vspj" : "accordion-body-opf";

    const [isOpenList, setIsOpenList] = useState(Array(faqs.length).fill(false));
    const toggleAccordion = (index) => {
        const newIsOpenList = [...isOpenList];
        newIsOpenList[index] = !newIsOpenList[index];
        setIsOpenList(newIsOpenList);
    };

    return (
        <div className="pb-4 accordion-spacing">
            <h1 className="text-center mb-4 pb-4">Často kladené otázky</h1>
            <div className="accordion">
                {faqs.map((faq, index) => (
                    <Card key={index}>
                        <CardHeader
                            className={accordionHeader}
                            onClick={() => toggleAccordion(index)}
                        >
                            {faq.question}
                            <span className={`accordion-icon ${isOpenList[index] ? 'minus' : 'plus'}`}></span>
                        </CardHeader>
                        <Collapse isOpen={isOpenList[index]}>
                            <CardBody className={accordionBody}>{faq.answer}</CardBody>
                        </Collapse>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default AccordionFaq;
