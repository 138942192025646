import React, { Component } from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import SessionSelector from "./components/SessionSelector";

export default class App extends Component {
    static displayName = App.name;

    render() {
        const { subdomain } = this.props;

        if (subdomain === 'slu' || subdomain === 'vspj')
            return (
            <Layout subdomain={subdomain}>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        const routeProps = {
                            ...rest,
                            element: React.cloneElement(element, { subdomain }),
                        };
                        return <Route key={index} {...routeProps} />;
                    })}
                </Routes>
            </Layout>
        );
        else
            return (<SessionSelector/>);
    }
}
