import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Banner.css';

export class Banner extends Component {
    static displayName = Banner.name;

    constructor (props) {
        super(props);
    }

    render() {
        const subdomain = window.location.host.split('.')[0];
        const schoolName = subdomain === 'vspj' ? "VŠPJ" : "OPF";
        const buttonColor = subdomain === 'vspj' ? "btn btn-vspj" : "btn btn-opf";
        const backgroundPicture = subdomain === 'vspj' ? "full-width-picture-vspj" : "full-width-picture-opf";
        return (
            <div className={backgroundPicture}>
                <div className="text-overlay text-light text-uppercase">
                    <Link to="/registration">
                        <button className={buttonColor}>Přihlásit se</button>
                    </Link>
                </div>
            </div>
        );
    }
}