import React, {useEffect, useState} from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row, NavItem,
} from "reactstrap";
import {Link, useLocation} from "react-router-dom";
import PersonalDataForm from "./PersonalData";

const EditRegistrationForm = () => {
    const subdomain = window.location.host.split('.')[0];
    const buttonColor = subdomain === 'vspj' ? "btn btn-vspj" : "btn btn-opf";

    const sessionOptions = subdomain === "vspj" ? [
        { value: 'FirstVspj', label: '1. turnus' },
        { value: 'SecondVspj', label: '2. turnus' }
    ] : [
        { value: 'FirstOpf', label: '1. turnus' },
        { value: 'SecondOpf', label: '2. turnus' }
    ];

    const departmentOptions = subdomain === "vspj" ? [
        { value: '', label: 'Vybrat...' },
        { value: 'AppliedInformaticsVspj', label: 'Aplikovaná informatika' },
        { value: 'AppliedTechnologyForIndustryVspj', label: 'Aplikovaná technika pro průmyslovou praxi' },
        { value: 'AppliedMechanicalEngineeringVspj', label: 'Aplikované strojírenství' },
        { value: 'TravelAndTourismVspj', label: 'Cestovní ruch' },
        { value: 'FinanceAndManagmentVspj', label: 'Finance a řízení' },
        { value: 'BirthAssistentVspj', label: 'Porodní asistentka' },
        { value: 'NurseVspj', label: 'Všeobecná sestra' },
        { value: 'HealthAndSocialCareVspj', label: 'Zdravotně sociální péče' },
        { value: 'OtherVspj', label: 'Jiný...' }
    ] : [
        { value: '', label: 'Vybrat...' },
        { value: 'BankingOpf', label: 'Bankovnictví, peněžnictví, účetnictví' },
        { value: 'TravelAndTourismOpf', label: 'Cestovní ruch a turismus' },
        { value: 'DigitalBusinessOpf', label: 'Digitální business' },
        { value: 'EconomicsAndManagementOpf', label: 'Economics and management' },
        { value: 'FinanceAndAccountingOpf', label: 'Finance a účetnictví' },
        { value: 'InnovativeBusinessOpf', label: 'Inovativní podnikání' },
        { value: 'SocialServicesMarketingOpf', label: 'Management v sociálních službách' },
        { value: 'ManagerialItOpf', label: 'Manažerská informatika' },
        { value: 'MarketingOpf', label: 'Marketing' },
        { value: 'InternationalTradeOpf', label: 'Mezinárodní obchod' },
        { value: 'TradeAndMarketingOpf', label: 'Obchod a marketing' },
        { value: 'BusinessOpf', label: 'Podnikání' },
        { value: 'PublicEconomyOpf', label: 'Veřejná ekonomika a správa' },
        { value: 'OtherOpf', label: 'Jiný...' }
    ];

    let { state } = useLocation();
    const [editingEnable, setEditingEnable] = useState(false);
    const [buttonLabel, setButtonLabel] = useState("Upravit údaje");
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phone: "",
        department: "",
        firstYear: "",
        birthDate: "",
        roommates: "",
        gender: "",
        session: "",
        transport: "",
        registrationStatus: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        populateData();
        setEditingEnable(false);
        setButtonLabel("Upravit údaje")
    }, []);

    async function populateData(email) {
        try {
            const url = `/editregistration?email=${encodeURIComponent(state.email)}`;
            const response = await fetch(url);
            const data = await response.json();
            setFormData(data);
        } catch (error) {
            console.error('Error fetching registrations:', error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (editingEnable) {
            setButtonLabel("Povolit úpravy");
            const response = await fetch('updateregistration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Data sent successfully');
            } else {
                console.error('Error sending data');
            }
            setEditingEnable(false);
        } else {
            setButtonLabel("Odeslat úpravy");
            setEditingEnable(true);
        }


    };

    return (
        <div>
        <div>
            <h1>Moje přihláška</h1>
            <Form onSubmit={handleSubmit}>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="firstName">Jméno</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                disabled={!editingEnable}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="lastName">Příjmení</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                disabled={!editingEnable}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="emailAddress">E-mail</Label>
                    <Input
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        disabled={true}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="phoneNumber">Telefonní číslo</Label>
                    <Input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        disabled={true}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="department">Fakulta</Label>
                    <Input
                        type="select"
                        name="department"
                        id="department"
                        value={formData.department}
                        onChange={handleChange}
                        disabled={!editingEnable}
                    >
                        {departmentOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <legend>Nastupuju do prváku:</legend>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="firstYear"
                                value="True"
                                onChange={handleChange}
                                disabled={!editingEnable}
                                checked={formData.firstYear === "True"}
                            />{" "}
                            Ano
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="firstYear"
                                value="False"
                                onChange={handleChange}
                                disabled={!editingEnable}
                                checked={formData.firstYear === "False"}
                            />{" "}
                            Ne
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="birthdate">Datum narození</Label>
                    <Input
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        value={formData.birthDate}
                        onChange={handleChange}
                        disabled={!editingEnable}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="roommates">Spolubydlící</Label>
                    <Input
                        type="text"
                        name="roommates"
                        id="roommates"
                        value={formData.roommates}
                        onChange={handleChange}
                        disabled={!editingEnable}
                    />
                    <small className="text-muted">
                        Zadej e-maily, tvojich kamarádů a odděl je čárkou [,]. Maximálně 3.
                    </small>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <legend>Pohlaví</legend>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="gender"
                                value="Male"
                                onChange={handleChange}
                                disabled={!editingEnable}
                                checked={formData.gender === "Male"}
                            />{" "}
                            Muž
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="gender"
                                value="Female"
                                onChange={handleChange}
                                disabled={!editingEnable}
                                checked={formData.gender === "Female"}
                            />{" "}
                            Žena
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="session">Turnus</Label>
                    <Input
                        type="select"
                        name="session"
                        id="session"
                        value={formData.session}
                        onChange={handleChange}
                        disabled={true}
                    >
                        {sessionOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="transportMode">Způsob dopravy</Label>
                    <Input
                        type="select"
                        name="transportMode"
                        id="transportMode"
                        value={formData.transportMode}
                        onChange={handleChange}
                        disabled={!editingEnable}
                    >
                        <option value="">Vybrat...</option>
                        <option value="car">Bus</option>
                        <option value="bus">Vlastní</option>
                    </Input>
                </FormGroup>
                <Button className={buttonColor} type="submit">
                    {buttonLabel}
                </Button>
            </Form>
            </div>
            {formData.registrationStatus === "PaidInFull" && <PersonalDataForm />}
        </div>
    );
};

export default EditRegistrationForm;

