import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../styles/SponsorsLogos.css';

const sponsorsOpf = [
    {
        image: require('../images/opf_logo.png'),
        title: 'OPF'
    },
    {
        image: require('../images/business-gate.png'),
        title: 'Business Gate'
    },
    // Add more sponsors as needed
];

const sponsorsVspj = [
    {
        image: require('../images/vspj_logo.png'),
        title: 'VŠPJ'
    },
    // Add more sponsors as needed
];

const SponsorLogos = () => {
    const subdomain = window.location.host.split('.')[0];
    const sponsors = subdomain === 'vspj'? sponsorsVspj: sponsorsOpf;

    return (
        <div className="sponsor-logo-spacing">
            <h1 className="text-center mb-4">Partneři</h1>
            <Container className="sponsor-logos-container">
                <Row>
                    {sponsors.map((sponsor, index) => (
                        <Col xs="6" md="4" className="sponsor-logo-wrapper" key={index}>
                            <div>
                                <img src={sponsor.image} alt={sponsor.title} className="sponsor-logo pb-2" />
                                <h5 className="text-center">{sponsor.title}</h5>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default SponsorLogos;
