import React, { Component } from 'react';
import {Banner} from "./Banner";
import {SessionDates} from "./SessionDates";
import Slider from "./Slider";
import SponsorLogos from "./SponsorLogos";
import AccordionFaq from "./AccordionFaq";

const slidesOpf = [
    {
        image: require('../images/adaptakopf.png'),
        heading: 'Adaptační kurz pro studenty OPF',
        listItems: [
            'Může jet každý - studenti bakalářského, magisterského, prezenčního i kombinovaného programu na OPF (na věku nezáleží)',
            'Dozvíš se, jak to chodí na naší Obchodně podnikatelské fakultě Slezské univerzity v Karviné ještě před začátkem semestru',
            'Instruktoři jsou tvoji budoucí spolužáci z vyšších ročníků OPF',
            'Dáme ti tips & tricks, jak se z toho nezbláznit a ulehčit si studium na vysoké'
        ]
    },
    {
        image: require('../images/horst.jpeg'),
        heading: 'Student friendly cena',
        listItems: [
            'V ceně máš vše: ubytko, plnou penzi, dopravu, nabitý program',
            'Záloha je 1290 Kč a potřebujeme ji poslat nejpozději do 6. 9. 2024',
            'Doplatek je 1990 Kč a ten budeme potřebovat nejpozději do 6. 9. 2024'
        ]
    },
    {
        image: require('../images/party.jpg'),
        heading: 'Nabitý program',
        listItems: [
            'Teambuildingy - Ať už jedeš sám/sama nebo s partou, seznámíš se s budoucími spolužáky a najdeš si nové kamarády',
            'Party - profi audio & DJs, akční drinky, světelná show, UV barvy',
            'Legendární Thirsty Games!',
            'Přednášky - školní elektronický systém, erasmus, základní info o škole,...'
        ]
    },
    {
        image: require('../images/kemp-opf/kemp-opf-1.jpg'),
        heading: 'Doprava a ubytování',
        listItems: [
            'Ubytovaní budeme v chatkách v táborovém kempu Funlandia (Protivanov)',
            'V kempu bude téměř nonstop otevřený bar s pořádnými zásobami pitného režimu, ale také snacky na zahnání půlnočního hladu',
            'Do kempu můžeš jet buď po vlastní ose (parkování v areálu zajištěno) nebo za kilčo pronajatým autobusem od OPF'
        ]
    }
];

const slidesVspj = [
    {
        image: require('../images/adaptakvspj.png'),
        heading: 'Adaptační kurz pro studenty VŠPJ',
        listItems: [
            'Může jet každý - studenti bakalářského, magisterského, prezenčního i kombinovaného programu na VŠPJ (na věku nezáleží)',
            'Dozvíš se, jak to chodí na naší Vysoké škole polytechnické v Jihlavě ještě před začátkem semestru',
            'Instruktoři jsou tvoji budoucí spolužáci z vyšších ročníků VŠPJ',
            'Dáme ti tips & tricks, jak se z toho nezbláznit a ulehčit si studium na vysoké'
        ]
    },
    {
        image: require('../images/horst.jpeg'),
        heading: 'Student friendly cena',
        listItems: [
            'V ceně máš vše: ubytko, plnou penzi, dopravu, nabitý program',
            'Záloha je 1290 Kč a potřebujeme ji poslat nejpozději do 6. 9. 2024',
            'Doplatek je 1990 Kč a ten budeme potřebovat nejpozději do 6. 9. 2024'
        ]
    },
    {
        image: require('../images/party.jpg'),
        heading: 'Nabitý program',
        listItems: [
            'Teambuildingy - Ať už jedeš sám/sama nebo s partou, seznámíš se s budoucími spolužáky a najdeš si nové kamarády',
            'Party - profi audio & DJs, akční drinky, světelná show, UV barvy',
            'Legendární Thirsty Games!',
            'Přednášky - školní elektronický systém, erasmus, základní info o škole,...'
        ]
    },
    {
        image: require('../images/kemp-opf/kemp-opf-1.jpg'),
        heading: 'Doprava a ubytování',
        listItems: [
            'Ubytovaní budeme v chatkách v táborovém kempu Funlandia (Protivanov)',
            'V kempu bude téměř nonstop otevřený bar s pořádnými zásobami pitného režimu, ale také snacky na zahnání půlnočního hladu',
            'Do kempu můžeš jet buď po vlastní ose (parkování v areálu zajištěno) nebo za kilčo pronajatým autobusem od VŠPJ'
        ]
    }
];

const faqsOpf = [
    {
        question: "Chci na Adapťák, ale bojím se jet sám/sama.",
        answer: "Ničeho se neboj. Nejsi jediný/a, kdo pojede sám/sama. Hned na prvním teambuildingu se seznámíte s budoucími spolužáky a najdeš si tam kamarády, se kterými si náš Adapťák užiješ. Ne nadarmo někdo adaptačnímu kurzu říká slučovák nebo seznamovák.",
    },
    {
        question: "Můžu jet na Adapťák, i když nejdu do prváku / na bakalářské studium / na prezenční studium?",
        answer: "Ano, program je udělaný tak, aby sis to u nás užil/a, i když jsi už na vysoké ostřílený mazák, nebo do školy nebudeš chodit tak často.",
    },
    {
        question: "Můžeme na adaptačním kurzu bydlet s kamarády společně na jedné chatce?",
        answer: "Ano, stačí, když v přihlášce v kolonce „spolubydlící“ zadáte s kamarády vaše emailové adresy, pod kterými jste se přihlásili.",
    },
    {
        question: "Co když mám během adaptačního kurzu přihlašování předmětů nebo seminářů?",
        answer: "Jedině dobře, protože ti se vším pomohou zkušení instruktoři, kteří jsou studenty vyšších ročníků na OPF.",
    },
    {
        question: "Jak je to se stravou?",
        answer: "V ceně za Adapťák je zahrnuta plná penze.",
    },
    {
        question: "Kde si mám zažádat o speciální stravu?",
        answer: "Ohledně speciální stravy nám napiš na adaptak.slu@gmail.com a domluvíme se. Stopro to nějak zařídíme, ale musíš nám dát vědět dopředu.",
    },
    {
        question: "Jaký je můj variabilní symbol pro platbu?",
        answer: "Tvůj variabilní symbol i platební údaje jsou na faktuře, kterou máš v příloze informačního emailu zaslaného po odeslání přihlášky na Adapťák.",
    },
    {
        question: "Co když jsem již zaplatil/a, ale nepřišel mi email s potvrzením o platbě?",
        answer: "Email s potvrzením o platbě by měl přijít do 5 pracovních dnů. Pokud ti i po vypršení této lhůty nic nepřišlo, zkontroluj prosím hromadné emaily a spam. Kdyby ani tam nic nebylo, napiš nám na adaptak.slu@gmail.com a my se na to podíváme.",
    },
    {
        question: "Co když mi nepřišel informační e-mail s potvrzením o přijetí přihlášky?",
        answer: "Zkontroluj prosím hromadné emaily a spam. Pokud by ani tam nic nebylo, napiš nám na adaptak.slu@gmail.com a informační mail ti pošleme znovu.",
    },
    {
        question: "Mohu jet na oba termíny současně nebo naopak jen na část jednoho termínu?",
        answer: "Ano, pokud však jedeš na oba termíny, musíš podat 2 přihlášky na každý termín zvlášť (je potřeba použít jiný e-mail pro každou přihlášku).",
    },
    {
        question: "Je na baru možnost platit kartou?",
        answer: "Ano, ale preferujeme hotovost.",
    },
    {
        question: "Mohou bydlet i kluci s holkama a naopak?",
        answer: "Pokud se domluvíte, tak ano, ale jinak systém rozřazuje dle oborů a pohlaví.",
    },
    {
        question: "Co nezapomenout s sebou?",
        answer: "Nezapomeň občanský průkaz a určitě se ti bude hodit i nějaká ta hotovost.",
    },
    {
        question: "Budou z Adapťáku nějaké fotky nebo videa?",
        answer: "Po celou dobu akce s námi budou profesionální fotografové a kameramani, jejichž výtvory následně zveřejníme v uzavřené facebookové skupině Adapťák SLU, kde budou ready ke stažení.",
    },
    {
        question: "Ztráty a nálezy?",
        answer: "Veškeré ztráty a nálezy po adaptačním kurzu zveřejníme na našich sociálních sítích a předáme jejich právoplatným majitelům.",
    }];

const faqsVspj = [
    {
        question: "Chci na Adapťák, ale bojím se jet sám/sama.",
        answer: "Ničeho se neboj. Nejsi jediný/a, kdo pojede sám/sama. Hned na prvním teambuildingu se seznámíte s budoucími spolužáky a najdeš si tam kamarády, se kterými si náš Adapťák užiješ. Ne nadarmo někdo adaptačnímu kurzu říká slučovák nebo seznamovák.",
    },
    {
        question: "Můžu jet na Adapťák, i když nejdu do prváku / na bakalářské studium / na prezenční studium?",
        answer: "Ano, program je udělaný tak, aby sis to u nás užil/a, i když jsi už na vysoké ostřílený mazák, nebo do školy nebudeš chodit tak často.",
    },
    {
        question: "Můžeme na adaptačním kurzu bydlet s kamarády společně na jedné chatce?",
        answer: "Ano, stačí, když v přihlášce v kolonce „spolubydlící“ zadáte s kamarády vaše emailové adresy, pod kterými jste se přihlásili.",
    },
    {
        question: "Co když mám během adaptačního kurzu přihlašování předmětů nebo seminářů?",
        answer: "Jedině dobře, protože ti se vším pomohou zkušení instruktoři, kteří jsou studenty vyšších ročníků na VŠPJ.",
    },
    {
        question: "Jak je to se stravou?",
        answer: "V ceně za Adapťák je zahrnuta plná penze.",
    },
    {
        question: "Kde si mám zažádat o speciální stravu?",
        answer: "Ohledně speciální stravy nám napiš na adaptak.vspj@gmail.com a domluvíme se. Stopro to nějak zařídíme, ale musíš nám dát vědět dopředu.",
    },
    {
        question: "Jaký je můj variabilní symbol pro platbu?",
        answer: "Tvůj variabilní symbol i platební údaje jsou na faktuře, kterou máš v příloze informačního emailu zaslaného po odeslání přihlášky na Adapťák.",
    },
    {
        question: "Co když jsem již zaplatil/a, ale nepřišel mi email s potvrzením o platbě?",
        answer: "Email s potvrzením o platbě by měl přijít do 5 pracovních dnů. Pokud ti i po vypršení této lhůty nic nepřišlo, zkontroluj prosím hromadné emaily a spam. Kdyby ani tam nic nebylo, napiš nám na adaptak.vspj@gmail.com a my se na to podíváme.",
    },
    {
        question: "Co když mi nepřišel informační e-mail s potvrzením o přijetí přihlášky?",
        answer: "Zkontroluj prosím hromadné emaily a spam. Pokud by ani tam nic nebylo, napiš nám na adaptak.vspj@gmail.com a informační mail ti pošleme znovu.",
    },
    {
        question: "Mohu jet na oba termíny současně nebo naopak jen na část jednoho termínu?",
        answer: "Ano, pokud však jedeš na oba termíny, musíš podat 2 přihlášky na každý termín zvlášť (je potřeba použít jiný e-mail pro každou přihlášku).",
    },
    {
        question: "Je na baru možnost platit kartou?",
        answer: "Ano, ale preferujeme hotovost.",
    },
    {
        question: "Mohou bydlet i kluci s holkama a naopak?",
        answer: "Pokud se domluvíte, tak ano, ale jinak systém rozřazuje dle oborů a pohlaví.",
    },
    {
        question: "Co nezapomenout s sebou?",
        answer: "Nezapomeň občanský průkaz a určitě se ti bude hodit i nějaká ta hotovost.",
    },
    {
        question: "Budou z Adapťáku nějaké fotky nebo videa?",
        answer: "Po celou dobu akce s námi budou profesionální fotografové a kameramani, jejichž výtvory následně zveřejníme v uzavřené facebookové skupině Adapťák VŠPJ, kde budou ready ke stažení.",
    },
    {
        question: "Ztráty a nálezy?",
        answer: "Veškeré ztráty a nálezy po adaptačním kurzu zveřejníme na našich sociálních sítích a předáme jejich právoplatným majitelům.",
    }];

export class Home extends Component {
  static displayName = Home.name;

  render() {
      const subdomain = window.location.host.split('.')[0];
    return (
        <div>
            <Banner />
            <SessionDates />
            <Slider slidesOpf={slidesOpf} slidesVspj={slidesVspj}/>
            <SponsorLogos/>
            <AccordionFaq faqs={subdomain === 'vspj' ? faqsVspj : faqsOpf} />
        </div>
    );
  }
}
