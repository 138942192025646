import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../styles/Gallery.css';

const categoriesOpf = [
    {
        name: 'Ubytování',
        images: [
            { src: require('../images/kemp-opf/kemp-opf-1.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-2.jpeg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-3.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-4.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-5.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-6.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-7.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-8.jpg'), title: '' }
        ],
    },
    {
        name: '2023',
        images: [
            { src: require('../images/fotky-opf/fotka-opf-1.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-2.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-3.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-4.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-5.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-6.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-7.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-8.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-9.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-10.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-11.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-12.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-13.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-14.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-15.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-16.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-17.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-18.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-19.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-20.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-21.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-22.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-23.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-24.jpg'), title: '' },
            { src: require('../images/fotky-opf/fotka-opf-25.jpg'), title: '' },
        ],
    },
    // Add more categories as needed
];

const categoriesVspj = [
    {
        name: 'Ubytování',
        images: [
            { src: require('../images/kemp-opf/kemp-opf-1.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-2.jpeg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-3.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-4.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-5.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-6.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-7.jpg'), title: '' },
            { src: require('../images/kemp-opf/kemp-opf-8.jpg'), title: '' }
        ],
    },
    {
        name: '2023',
        images: [
            { src: require('../images/fotky-vspj/fotka-vspj-1.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-2.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-3.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-4.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-5.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-6.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-7.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-8.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-9.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-10.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-11.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-12.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-13.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-14.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-15.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-16.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-17.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-18.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-19.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-20.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-21.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-22.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-23.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-24.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-25.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-26.JPG'), title: '' },
            { src: require('../images/fotky-vspj/fotka-vspj-27.JPG'), title: '' },
        ],
    },
    // Add more categories as needed
];

export function Gallery() {
    const subdomain = window.location.host.split('.')[0];
    const categories = subdomain === 'vspj' ? categoriesVspj : categoriesOpf;
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    function handleCategoryClick(category) {
        setSelectedCategory(category);
    }

    return (
        <Container className="full-height">
            <Row className="mt-4">
                <Col>
                    <nav>
                        {categories.map((category) => (
                            <button
                                key={category.name}
                                onClick={() => handleCategoryClick(category)}
                                className={
                                    category === selectedCategory
                                        ? subdomain === 'vspj'
                                            ? 'gallery-button-active-vspj'
                                            : 'gallery-button-active-opf'
                                        : 'gallery-button'
                                }
                            >
                                {category.name}
                            </button>
                        ))}
                    </nav>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="gallery">
                        {categories.map((category) => (
                            <div
                                key={category.name}
                                style={{
                                    display: category === selectedCategory ? 'flex' : 'none',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                {category.images.map((image) => (
                                    <div key={image.src} className="gallery-image-wrapper">
                                        <img src={image.src} alt={category.name} className="gallery-image" />
                                        {image.title && <div className="image-title">{image.title}</div>}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
