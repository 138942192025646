import React from 'react';
import {Container, Row, Col, NavItem, NavLink} from 'reactstrap';
import '../styles/Contact.css'
import {FaEnvelope, FaFacebookF, FaInstagram} from "react-icons/fa";

const ThankYou = () => {
    return (
        <Container fluid className="d-flex justify-content-center align-items-center mt-5 full-height">
            <Row>
                <Col>
                    <h1 className="text-center">Tvoje přihlášení proběhlo úspěšně!</h1>
                    <h4 className="text-center">Brzo ti dorazí e-mail s podrobnými informacemi.</h4>
                </Col>
            </Row>
        </Container>
    );
};

export default ThankYou;