import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import { AdminNav } from './admin/AdminNav';
import AdminLogin from './admin/AdminLogin'; // Import the AdminLogin component
import './styles/admin.css';
import Registrations from "./admin/Registrations";

export default class Admin extends Component {
    static displayName = Admin.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false, // Set the initial login status to false
        };
    }

    handleLogin = (firstName, userRole) => {
        // Logic to handle the login process
        // For simplicity, let's assume the login is successful
        this.setState({
            isLoggedIn: true,
            firstName: firstName,
            userRole: userRole,
        });
    };


    render() {
        const { isLoggedIn, firstName, userRole } = this.state;

        return (
            <div>
                <AdminNav firstName={firstName} userRole={userRole} isLoggedIn={isLoggedIn}/>
                {isLoggedIn ? (
                    <Registrations />
                ) : (
                    <AdminLogin onLogin={this.handleLogin} />
                )}
            </div>
        );
    }

}
