import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import '../styles/Login.css';
import { useNavigate } from 'react-router-dom';

const AdminLogin = ({ onLogin, firstName, userRole }) => {
    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const formData = {
            emailAddress: email,
            password: password
        };

        const response = await fetch('adminlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            const data = await response.json();
            const { firstName, userRole } = data;
            onLogin(firstName, userRole);
        } else {
            window.alert('Špatný e-mail nebo heslo.');
        }
    };

    return (
        <Form onSubmit={handleLogin} className="mt-4 full-height w-50 mx-auto">
            <FormGroup>
                <Label for="email">E-mail</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Zadej e-mail"
                    value={email}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">Heslo</Label>
                <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Zadej heslo"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormGroup>
            <Button type="submit">Log in</Button>
        </Form>
    );
};

export default AdminLogin;
