import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from "./Footer";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        const { subdomain } = this.props;

        return (
            <div>
                <NavMenu subdomain={subdomain} />
                <Container tag="main" subdomain={subdomain}>
                    {this.props.children}
                </Container>
                <Footer subdomain={subdomain}/>
            </div>
        );
    }
}