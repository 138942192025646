import React from 'react';
import { Container, Row, Col, NavItem, NavLink } from 'reactstrap';
import { FaFacebookF, FaInstagram, FaEnvelope } from "react-icons/fa";
import { BiCopyright } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import '../styles/Footer.css';

const Footer = () => {
    const navigate = useNavigate();

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page with smooth behavior
    };

    const handleRedirect = (route) => {
        handleLinkClick(); // Scroll to the top of the page
        navigate(route); // Redirect to the specified route

    };

    const subdomain = window.location.host.split('.')[0];
    const logoImage = subdomain === 'vspj' ? require('../images/vspj_logo.png') : require('../images/opf_logo.png');
    const lastNavLink = subdomain === 'vspj' ? 'text-light last-nav-link-vspj' : 'text-light last-nav-link-opf';
    const facebook = subdomain === 'vspj' ? "https://www.facebook.com/adaptak.vspj" : "https://www.facebook.com/adaptak.slu";
    const instagram = subdomain === 'vspj' ? "https://www.instagram.com/adaptak.vspj" : "https://www.instagram.com/adaptak.slu";
    const emailAddress = subdomain === 'vspj' ? "mailto:adaptak.vspj@gmail.com" : "mailto:adaptak.slu@gmail.com";

    return (
        <footer className="py-3">
            <Container>
                <Row className="text-center text-md-end text-uppercase pt-3 pb-3 mb-3 border-top border-bottom">
                    <nav className="nav justify-content-evenly">
                        <NavItem>
                            <NavLink as={Link} className="text-dark" to="/" onClick={() => handleRedirect('/')}>
                                Domů
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} className="text-dark" to="/gallery" onClick={() => handleRedirect('/gallery')}>
                                Galerie
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} className="text-dark" to="/contact" onClick={() => handleRedirect('/contact')}>
                                Kontakt
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} className={lastNavLink} to="/registration" onClick={() => handleRedirect('/registration')}>
                                Přihláška
                            </NavLink>
                        </NavItem>
                    </nav>
                </Row>
                <Row>
                    <Col md="6" className="text-md-start mb-3 mb-md-0 d-flex align-items-center logo-footer">
                        <div>
                            <img alt="Adapťák logo" src={require('../images/adaptak_logo.png')} className="me-2 logo" />
                        </div>
                        <div className="text-start">
                            <div>Adapťák</div>
                            <div className="text-muted">Pro studenty</div>
                        </div>
                        <div>
                            <img alt="VŠPJ logo" src={logoImage} className="ms-2 logo" />
                        </div>
                    </Col>
                    <Col md="6" className="text-center text-md-end">
                        <nav className="nav justify-content-center justify-content-md-end">
                            <NavItem>
                                <NavLink href={instagram} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    <FaInstagram className="me-2" />
                                    Instagram
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={facebook} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    <FaFacebookF className="me-2" />
                                    Facebook
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={emailAddress} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    <FaEnvelope className="me-2" />
                                    E-mail
                                </NavLink>
                            </NavItem>
                        </nav>
                    </Col>
                </Row>
                <Row className="text-center border-top mt-3 pt-3">
                    <p>
                        2023
                        <BiCopyright className="me-2 ms-2" />
                        Adapťák s.r.o.
                    </p>
                </Row>
                <Row className="text-center border-top">
                    <nav className="nav justify-content-center">
                        <NavItem>
                            <NavLink as={Link} href="obchodni-podminky-adaptak.pdf"
                                     target="_blank"
                                     rel="noopener noreferrer"
                                     className="text-dark">
                                Obchodní podmínky
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink as={Link} href="gdpr-adaptak.pdf"
                                     target="_blank"
                                     rel="noopener noreferrer"
                                     className="text-dark">
                                GDPR
                            </NavLink>
                        </NavItem>
                    </nav>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
