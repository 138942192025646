import React from 'react';
import {Container, Row, Col, NavItem, NavLink} from 'reactstrap';
import '../styles/Contact.css'
import {FaEnvelope, FaFacebookF, FaInstagram} from "react-icons/fa";

const Contact = () => {
    const subdomain = window.location.host.split('.')[0];
    const facebook = subdomain === 'vspj' ? "https://www.facebook.com/adaptak.vspj" : "https://www.facebook.com/adaptak.slu";
    const facebookDisplayed = subdomain === "vspj" ? "Adapťák VŠPJ" : "Adapťák SLU";
    const instagramDisplayed = subdomain === "vspj" ? "adaptak.vspj" : "adaptak.slu";
    const instagram = subdomain === 'vspj' ? "https://www.instagram.com/adaptak.vspj" : "https://www.instagram.com/adaptak.slu";
    const emailAddress = subdomain === 'vspj' ? "mailto:adaptak.vspj@gmail.com" : "mailto:adaptak.slu@gmail.com";
    const emailAddressDisplayed = subdomain === 'vspj' ? "adaptak.vspj@gmail.com" : "adaptak.slu@gmail.com";
    return (
        <Container fluid className="d-flex justify-content-center align-items-center mt-5 full-height">
            <Row>
                <Col>
                    <h1 className="text-center">Důležité kontakty</h1>
                    <ul className="list-unstyled text-center">
                        <NavItem>
                            <NavLink href={instagram} target="_blank" rel="noopener noreferrer" className="text-dark mt-5 mb-5"><FaInstagram className="me-2" />{instagramDisplayed}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={facebook} target="_blank" rel="noopener noreferrer" className="text-dark mt-5 mb-5"><FaFacebookF className="me-2" />{facebookDisplayed}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={emailAddress} className="text-dark mt-5 mb-5"><FaEnvelope className="me-2" />{emailAddressDisplayed}</NavLink>
                        </NavItem>
                        <li className="mt-5 mb-5">
                            <p>Adapťák pořádá Adapťák s.r.o.</p>
                            <p>IČO: 19226985</p>
                            <p>DIČ: CZ19226985</p>
                            <p>Společnost je zapsána v obchodním rejstříku vedeném krajským soudem v Hradci Králové, oddíl C, vložka 51033</p>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;