import React, {useEffect, useState} from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Registrations = () => {
    const [registrations, setRegistrations] = useState([]);
    const [selectedSession, setSelectedSession] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {
        try {
            const response = await fetch('registrationlist');
            const data = await response.json();
            setRegistrations(data);
        } catch (error) {
            console.error('Error fetching registrations:', error);
        }
    }

    const handleSessionChange = (e) => {
        setSelectedSession(e.target.value);
    };
    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleExportRegistrationAll = () => {
        const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredRegistrations);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations');
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const buffer = new ArrayBuffer(excelFile.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < excelFile.length; i++) {
            view[i] = excelFile.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `registrations-${currentDate}.xlsx`);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredRegistrations = registrations.filter((registration) => {
        const fullName = registration.firstName + ' ' + registration.lastName;
        const nameMatch = fullName.toLowerCase().includes(searchTerm.toLowerCase());
        const sessionMatch = selectedSession === 'All' || registration.session === selectedSession;
        const statusMatch = selectedStatus === 'All' || registration.registrationStatus === selectedStatus;
        return nameMatch && sessionMatch && statusMatch;
    });

    return (
        <>
            <h1>Přihlášky</h1>
            <h3>Počet přihlášených: {filteredRegistrations.length}</h3>
            <label htmlFor="name-search">Name:</label>
            <input id="name-search" type="text" value={searchTerm} onChange={handleSearch} />
            <div>
                <label htmlFor="session-select">Session:</label>
                <select id="session-select" value={selectedSession} onChange={handleSessionChange}>
                    <option key='All' value='All'>All</option>
                    {[...new Set(registrations.map(registration => registration.session))].map(session => (
                        <option key={session} value={session}>{session}</option>
                    ))}
                </select>
                <label htmlFor="status-select">Status:</label>
                <select id="status-select" value={selectedStatus} onChange={handleStatusChange}>
                    <option key='All' value='All'>All</option>
                    {[...new Set(registrations.map(registration => registration.registrationStatus))].map(status => (
                        <option key={status} value={status}>{status}</option>
                    ))}
                </select>

                <button onClick={handleExportRegistrationAll}>Export All</button>
                <button onClick={populateData}>Refresh</button>
            </div>
            <Table>
                <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Session</th>
                    <th>Department</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {filteredRegistrations.map((registration) => (
                    <tr key={registration.id}>
                        <td>{registration.firstName}</td>
                        <td>{registration.lastName}</td>
                        <td>{registration.emailAddress}</td>
                        <td>{registration.session}</td>
                        <td>{registration.department}</td>
                        <td>{registration.registrationStatus}</td>
                        <td>
                            <Link
                                to="/registration-detail"
                                state={{email: registration.emailAddress}}
                            >
                                Detail
                            </Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default Registrations;