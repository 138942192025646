import React, { useState, useEffect } from 'react';
import '../styles/Slider.css';
import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im';

const Slider = ({ slidesOpf, slidesVspj, autoSlideDelay = 10000 }) => {
    const subdomain = window.location.host.split('.')[0];
    const borderColor = subdomain === 'vspj' ? "slider-content-vspj" : "slider-content-opf";
    const slides = subdomain === "vspj" ? slidesVspj : slidesOpf;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isAutoSliding, setIsAutoSliding] = useState(true);
    const [isSliding, setIsSliding] = useState(false);

    useEffect(() => {
        let interval;
        if (isAutoSliding) {
            interval = setInterval(() => {
                setCurrentSlideIndex((currentSlideIndex + 1) % slides.length);
            }, autoSlideDelay);
        }

        return () => clearInterval(interval);
    }, [currentSlideIndex, isAutoSliding, slides.length, autoSlideDelay]);

    const handlePrevious = () => {
        setIsSliding(true);
        setTimeout(() => {
            setCurrentSlideIndex((currentSlideIndex - 1 + slides.length) % slides.length);
            setIsSliding(false);
            setIsAutoSliding(false);
        }, 500);
    };

    const handleNext = () => {
        setIsSliding(true);
        setTimeout(() => {
            setCurrentSlideIndex((currentSlideIndex + 1) % slides.length);
            setIsSliding(false);
            setIsAutoSliding(false);
        }, 500);
    };

    const handleSlideClick = (index) => {
        setCurrentSlideIndex(index);
        setIsAutoSliding(false);
    };

    const currentSlide = slides[currentSlideIndex];

    return (
        <div className="text-center pt-5 mb-4">
            <h1 className="mb-4 slider-heading mx-auto text-dark">Proč jet na Adapťák?</h1>
            <div className="slider-container p-2">
                <button
                    className={`slider-button slider-button-left ${isSliding ? 'slide-transition' : ''}`}
                    onClick={handlePrevious}
                >
                    <ImArrowLeft2 color="#333" size={24} />
                </button>
                <div className={borderColor}>
                    <div className={`slider-content-left ${isSliding ? 'slide-transition' : ''}`}>
                        <img src={currentSlide.image} alt="Slide" className="slider-image" />
                    </div>
                    <div className={`slider-content-right ${isSliding ? 'slide-transition' : ''}`}>
                        <h3 className="text-center pb-2 pe-4 ps-4">{currentSlide.heading}</h3>
                        <ul>
                            {currentSlide.listItems.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <button
                    className={`slider-button slider-button-right ${isSliding ? 'slide-transition' : ''}`}
                    onClick={handleNext}
                >
                    <ImArrowRight2 color="#333" size={24} />
                </button>
            </div>
            <div className="slider-dots pb-4">
                {slides.map((slide, index) => (
                    <span
                        key={index}
                        className={`slider-dot ${currentSlideIndex === index ? 'active' : ''}`}
                        onClick={() => handleSlideClick(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Slider;
